<template>
  <button
    class="base-button btn"
    :class="[className, { 'is-loading': isLoading }, { 'base-button--disable': disabled }]"
    :style="style"
    :disabled="disabled"
    :type="type"
    @click.prevent="clickBtn()"
  >
    <div class="base-button--content">
      <span v-if="isLoading" class="icon-load">
        <BaseImg :src="'/assets/images/components/common/icon-loading.svg'" alt="loading" />
      </span>
      <slot />
    </div>
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    className?: string
    width?: number
    disabled?: boolean
    isLoading?: boolean
    type?: 'button' | 'submit' | 'reset'
  }>(),
  {
    className: '',
    width: 0,
    disabled: false,
    isLoading: false,
    type: 'button'
  }
)

const style = computed(() => {
  const array = []
  props.width && array.push(...[{ width: formatUnit(props.width) }, { minWidth: formatUnit(props.width) }])
  return array
})

const emit = defineEmits(['click'])

const clickBtn = () => {
  if (!props.disabled && !props.isLoading) {
    emit('click')
  }
}

const formatUnit = (value: number) => {
  const baseWidth = 375
  const { $device } = useNuxtApp()
  if ($device.isDesktop) {
    return value + 'px'
  }
  return (value * 100) / baseWidth + 'vw'
}
</script>

<style lang="scss" scoped src="~/assets/scss/components/common/base-button.scss"></style>
